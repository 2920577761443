<template>
  <article>
    <hgroup><h1>ThinQ가전 제어 컴포넌트 예제 (vue.js, 에어컨/세탁기/도어윈도우센서)</h1></hgroup>
    Public component인<router-link to="/docs/thinq/api" style="margin-left: 8px;">com.damda.thinq-backend-api</router-link>를 활용하여 ThinQ 가전 상태조회 및 제어 애플리케이션을 컴포넌트로 생성하여 배포하는 튜토리얼입니다.
    <h2>Prerequisites</h2>
    <ul class="checklist">
      <li><a href="https://nodejs.org/ko/" target="_blank">node</a></li>
      <li>npm : node 설치시 자동으로 설치됨</li>
      <li><a href="https://cli.vuejs.org/guide/installation.html" target="_blank">vue-cil</a></li>
      <li>ThinQ 엡에서 실기기 등록 or ThinQ Simulator(<a href="https://simulator.lgthinq.com/" target="_blank">https://simulator.lgthinq.com/</a>) 가상기기 등록 (LG Account, KR)</li>
    </ul>
    <h2>Dependency</h2>
    아래 public component를 사용하는 컴포넌트 입니다.
    <ul class="checklist">
      <li><router-link to="/docs/thinq/api" style="margin-left: 4px;">com.damda.thinq-backend-api</router-link></li>
    </ul>
    <h2>웹 애플리케이션 로컬에서 실행</h2>
    &#9678; 로컬에서의 실행은 코드가 정상인지 확인하는 용도이며 백엔드 연동이 안되어 프로그램이 정상적으로 동작하지는 않습니다.
    <ol>
      <li><a href="/assets/img/thinq-web-front-example.zip" target="_blank"> 샘플 코드</a> 다운로드</li>
      <li>
        압축 해제 후 터미널에서 다음 명령 실행
      </li>
      <div>
        <pre><code class='hljs'>npm install
npm run serve</code></pre>
      </div>
      <li>
        <a href="http://localhost:9001 " target="_blank">http://localhost:9001</a> 로 접속하여 로그인 페이지 확인
      </li>
    </ol>
    <h2>웹 애플리케이션 Damda 컴포넌트로 실행</h2>
    <h4>컴포넌트 생성</h4>
    <div>
      <ol>
        <li><a href="/assets/img/thinq-web-front-example.zip" target="_blank"> 샘플 코드</a> 다운로드</li>
        <li>
          코드 빌드하기
        </li>
        <div>
        <pre><code class='hljs'>npm install
npm run build</code></pre>
        </div>
        <li>
          빌드된 웹 어플리케이션 파일 압축하기 (thinqweb.zip)
        </li>
        빌드된 dist 폴더의 모든 파일을 압축합니다 <br>
        <ul style="line-height:34px">
          <li>
             dist/*
          </li>
        </ul>
        <li>
          <a href="https://damda.lge.com/" target="_blank"> DAMDA console</a> 로그인 &gt; 컴포넌트 &gt; 컴포넌트 추가
        </li>
        <li>
          컴포넌트 생성을 위해 필요한 정보를 넣어 줍니다.
        </li>
        <ul style="line-height:34px">
          <li>
            컴포넌트명 : "com.damda.sample.thinqweb-example" 입력<br>
            - Unique한 이름을 지정해야 함. damda 플랫폼 내 중복되는 이름의 컴포넌트가 존재하는 경우 컴포넌트 생성 불가<br>
            - 컴포넌트명 추천 형식 : com.&lt;개인 식별 키워드&gt;.sample.thinqweb-example
          </li>
          <li>설명 : ‘Thinq web front 예제’ 입력</li>
          <li>
            코드 : '3. 예제 코드 파일 압축' 단계에서 생성한 'thinqweb.zip' 파일 업로드
          </li>
          <li>
            실행 스크립트 : 코드가 디바이스에 설치 후 실행하기 위해 필요한 스크립트 작성
          </li>
          <div>
        <pre><code class='hljs'>npx http-server {root}/dist -p 9019</code></pre>
          </div>
          <li>홈 화면 출력' 체크</li>
          <li>썸네일 이미지 : 자유롭게 선택</li>
          <li>앱 이름 : 'ThinqWeb예제' 입력</li>
          <li>
            접속 링크 : '<span class="link-font">http://127.0.0.1:9019</span>' 입력 <br />
            - 홈 화면에서 썸네일 클릭 시 이동할 링크 주소
          </li>
        </ul>
        <img
            src="/assets/img/example3-1.png"
            alt=""
            style="margin-bottom: 60px; margin-top: 10px;"
        />
        <li>
          컴포넌트 생성 완료
        </li>
        <img
            src="/assets/img/example3-2.png"
            alt=""
            style="margin-bottom: 60px; margin-top: 10px;"
        />
      </ol>
    </div>
    <h4>컴포넌트 배포</h4>
    <div>
      <ol>
        <li>배포할 컴포넌트를 모두 선택 &gt; 배포하기 (public 컴포넌트는 기본적으로 모두 배포 리스트에 추가됨)</li>
        <img
          src="/assets/img/example3-3.png"
          alt=""
          style="margin-bottom: 60px; margin-top: 40px;"
        />
        <li>배포할 컴포넌트 리스트를 확인 &gt; 배포할 "코어 디바이스 선택" &gt; 배포요청</li>
        <img
          src="/assets/img/example3-4.png"
          alt=""
          style="margin-bottom: 60px; margin-top: 40px;"
        />
      </ol>
    </div>
    <h4>컴포넌트 실행 확인</h4>
    <div>
      <ol>
        <li>배포 완료 후 라즈베리파이에서 "<span class="link-font">http://localhost:8002</span>" 접속</li>
        다음과 같이 홈 화면에 위에서 배포한 ThinQ web front 예제 컴포넌트가 보이는 것을 확인합니다.
        <img
            src="/assets/img/example3-5.png"
            alt=""
            style="margin-bottom: 60px; margin-top: 40px;"
        />
        <li>
          아이콘을 클릭하여 배포한 컴포넌트 웹 애플리케이션 실행
        </li>
        웹 페이지에 연결되면 lg account 로그인 후 가전 상태조회 및 컨트롤을 테스트 할 수 있습니다. <br>
        ※ 기기 상태(Event) 및 PUSH를 확인하기 위해서는 Subscribe Event/Push 버튼을 클릭하여야 합니다.
        <img
            src="/assets/img/example3-6.png"
            alt=""
            style="margin-bottom: 60px; margin-top: 40px;"
        />
      </ol>
    </div>
    <h2>Tips</h2>
    <h4>Vue.js 가이드</h4>
    <a href="https://kr.vuejs.org/v2/guide/" target="_blank"> https://kr.vuejs.org/v2/guide/</a>
    <br>
    <br>
    <h4>예제 디렉토리 구조</h4>
    <img
        src="/assets/img/stree.png"
        alt=""
        style="margin-bottom: 20px; margin-top: 20px;"
    />
    <ol>
      <li>dist</li>
      프로젝트 빌드 Target 디렉토리 : npm run build 명령으로 Web Application 생성, 빌드 이전에는 존재하지 않음
      <li>node_modules</li>
      npm install 명령을 실행하게 되면 npm 모듈들이 설치되는 디렉토리, npm install 이전에는 존재하지 않음
      <li>public</li>
      Web Public 파일들, 빌드시 Web Root에 복사됨
      <li>src</li>
      Vue.js 소스 파일들이 위치하는 디렉토리
      <ul>
        <li>assets</li>
        Vue.js에서 쓰이는 Static 파일 (ex: 이미지)
        <li>router</li>
        페이지 이동에 필요한 Routing 설정
        <li>store</li>
        페이지의 글로벌한 데이터 저장이나, 페이지 이동시 데이터 전달에 사용
        <li>views</li>
        <strong>View 화면구현 파일</strong>들이 위치 (페이지 템플릿, 로직 등, 실질적인 화면 구현)
        <li>App.vue</li>
        Vue.js 최상위 컨테이너 파일
        <li>main.js</li>
        Vue.js 어플리케이션 진입점, App.vue를 로드
      </ul>
    </ol>
    <br>
    <h4>Vue.js 로드 구조</h4>
    <img
        src="/assets/img/sflow.png"
        alt=""
        style="margin-bottom: 20px; margin-top: 10px;"
    />
    <h4>예제 화면 소스 리뷰</h4>
    <ol>
    <li>Login.vue</li>
    ThinQ Account 로그인
      <ul>
        <li>1~15 line</li>
        Vue.js 템플릿, View에 사용되는 Html과 Email, Password 변수(data) Binding
        <li>23~26 line</li>
        Vue.js에서 사용할 변수(data) 정의
        <li>27~54 line</li>
        methods: Vue.js에서 사용할 Method(Function) 정의
        <li>28~48 line</li>
        로그인 로직, Damda 공통 모듈 com.damda.thinq-backend-api의 /token Api 호출<br>
        계정 확인 후 sessionStorage에 acccess_token 저장 및 Vue.js Store의 'setAuth'를 호출하여 로그인 상태 true 저장
        <li>49~53 line</li>
        Form Reset Function (사용하지 않음)
        <li>57~81 line</li>
        Vue.js Template에서 사용할 CSS 정의
      </ul>
    <br>
    <li>Device.vue</li>
    ThinQ Connect 가전 제어
      <ul>
        <li>1~121 line</li>
        Vue.js 템플릿, View에 사용되는 Html과 변수(data) Binding, 상태에 따른 Display 제어
        <li>127~157 line</li>
        Vue.js에서 사용할 변수(data) 정의<br>
        - devices_list: ThinQ Account에 등록된 기기 목록<br>
        - device_id: 에어컨, 세탁기, 도어윈도우센서의 기기 ID<br>
        - device_push: Push Subscription 메시지 수신 데이터 저장<br>
        - device_event: Event Subscription 메시지 수신 데이터 저장<br>
        - washer_status: 세탁기 상태 저장<br>
        - aircon_status: 에어컨 상태 저장<br>
        - door_status: 도어윈도우센서 상태 저장<br>
        - 이하 변수(Data) 정의: 기기 제어에 필요한 상태 저장 및 셀렉트 박스 옵션 들<br>
        <li>158~230 line</li>
        mounted: Vue.js 페이지 로드 후 실행 (Html onLoad와 같은 Life Cycle)<br>
        - 159~194 line: ThinQ Account에 등록된 기기 목록 조회, com.damda.thinq-backend-api의 /devices Api 호출<br>
        - 172 line: 에어컨, 세탁기, 도어윈도우센서 존재시 device_id를 Data에서 정의한 device_id에 저장<br>
        - 174~189 line: 등록된 기기의 상태 조회, com.damda.thinq-backend-api의 /devices/{device_id} Api 호출<br>
        <li>196~228 line</li>
        Event/Push Callback을 수신 하기 위한 WebSocket 연결<br>
        - 203~216 line: WebSocket 메시지 수신하게 되면 Event/Push 메시지 판별하여 device_event 또는 device_push변수에 저장
        <li>231~423 line</li>
        methods: Vue.js에서 사용할 Method(Function) 정의<br>
        - 232~252 line: 에어컨, 세탁기 전원 제어 payload, 12/16 line Click 이벤트 발생시 호출됨<br>
        - 253~269 line: 에어컨 온도 제어 payload, 19/22 line Click 이벤트 발생시 호출됨<br>
        - 270~277 line: 에어컨 모드 제어 payload, 36 line Change 이벤트 발생시 호출됨<br>
        - 278~285 line: 에어컨 팬스피드 제어 payload, 50 line Change 이벤트 발생시 호출됨<br>
        - 286~297 line: 세탁기 세탁 시작 payload, 74 line Click 이벤트 발생시 호출됨<br>
        - 298~373 line: 세탁기 모드 payload, 92 line Change 이벤트 발생시 호출됨<br>
        - 374~388 line: 가전 제어, com.damda.thinq-backend-api의 /devices/{device_id} Api 호출<br>
        - 389~405 line: Event Subscription 로직, 115~116 line Button Click 이벤트 발생시 호출됨<br>
        - 406~422 line: Push Subscription 로직, 117~118 line Button Click 이벤트 발생시 호출됨
        <li>424~447 line</li>
        computed: 계산된 Data정의, 참조하는 변수의 값이 변경되면 자동으로 계산된 값을 반환<br>
        - 425~432 line: isActiveAir, 에어컨 전원 ON/OFF 반환<br>
        - 433~435 line: isAirDisabled, 에어컨 전원 ON/OFF에 따른 화면 Disable 판단에 사용<br>
        - 436~443 line: isActiveWasher, 세탁기 전원 ON/OFF 반환<br>
        - 444~446 line: isWasherDisabled, 세탁기 전원 ON/OFF에 따른 화면 Disable 판단에 사용
        <li>448~472 line</li>
        watch: 참조하는 변수의 값이 변경되면 실행됨<br>
        - 449~459 line: device_event, 기기 Event Subscription 데이터 수신하면 기기 상태를 업데이트<br>
        - 460~471 line: device_push, 기기 Push Subscription 데이터 수신하면 Push code를 Alert으로 화면에 표시
        <li>475~657 line</li>
        Vue.js Template에서 사용할 CSS 정의
      </ul>
    </ol>
  </article>
</template>
<script>
import getStartedPage2Py from "raw-loader!@/assets/code/get-started-page2.py";
import getStartedPage2Sh from "raw-loader!@/assets/code/get-started-page2.sh";
import CodeViewer from "@/components/CodeViewer.vue";
export default {
  data() {
    return {
      getStartedPage2Py,
      getStartedPage2Sh,
    };
  },
  components: {
    CodeViewer,
  },
};
</script>
